/**=====================
  2.32 Touchspin CSS Start
==========================**/
.bootstrap-touchspin {
  input {
    &.touchspin {
      padding: 0 12px;
    }
  }
  .input-group .btn {
    padding: 0.5rem 1.75rem;
  }
  .touchspin-vertical-tab {
    .input-group {
      .input-group-btn-vertical {
        position: relative;
        white-space: nowrap;
        width: 1%;
        vertical-align: middle;
        display: table-cell;
        .bootstrap-touchspin-down {
          margin-top: -2px;
        }
        i {
          position: absolute;
          top: 4px;
          left: 7px;
          font-size: 10px;
          font-weight: normal;
        }
        >.btn {
          display: block;
          float: none;
          width: 100%;
          max-width: 100%;
          padding: 9px;
          margin-left: -1px;
          position: relative;
        }
      }
    }
  }
  .input-group {
    font-family: $font-nunito, $font-serif;
    .btn {
      border-radius: 0 !important;
    }
  }
  .dropdown-basic {
    .dropdown {
      .dropbtn {
        padding: 9px 30px;
      }
      .dropdown-content {
        z-index: 9999;
      }
    }
  }
}
// Custom touchspin arrows
.touchspin-wrapper {display: flex;
  align-items: center;
  gap: 4.5px;
  .btn-touchspin {
    border-radius: 5px;
    width: 32px;
    height: 32px;
    padding: 0;
  }
  input[type='number'] {
    width: 85px;
    padding: 3px;
    text-align: center;
    height: 30px;
  }
}

.rounded-touchspin {
  .btn-touchspin {
    border-radius: 50%;
  }
  .touchspin-wrapper {
    input[type="number"] {
      &.input-touchspin {
        border-radius: 15px;
      }
    }
  }
}
.pre-post-touchspin {
  .decrement-touchspin,
  .increment-touchspin,
  .input-group-text {
    padding: 7px 14px !important;
    border: 0;
  }

  .btn-outline-warning {
    padding: 7px 14px !important;
  }
  .input-group-text {
    background-color: var(--light-bg);
  }
  .input-touchspin {
    border: 1px solid var(--course-light-btn);
    border-width: 2px;
    text-align: center;
  }
  .input-group {
    width: auto;
    .input-touchspin {
      width: auto !important;
      @media (max-width:768px) {
        width: 100px !important;
      }
    }
  }
  &.bg-touchspin {
    button[type='button'] {
      border: 1px solid var(--course-light-btn);
      border-width: 2px;
      text-align: center;
    }
  }
  .input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/**=====================
    2.32 Touchspin CSS Ends
==========================**/
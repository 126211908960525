/**=====================
     2.11 Color CSS Start
==========================**/
.color-box {
  margin-bottom: -10px;

  button {
    margin-bottom: 10px;

    +button {
      margin-left: 5px;
    }
  }
}


.light-font {
  color: $theme-body-sub-title-color;
}

/**=====================
     2.11 Color CSS Ends
==========================**/
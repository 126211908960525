/**=====================
    2.36 Scrollbar CSS Start
==========================**/
[data-simplebar] {
    position: unset;
}
.simplebar-mask {
    top: 32px;
}
.simplebar-track {
    right: -2px;
    &.simplebar-vertical {
        top: 100px;
        width: 10px;
    }
    &.simplebar-horizontal {
        visibility: hidden !important;
    }
}
.simplebar-scrollbar {
    &:before {
        background: rgba($primary-color, 0.2);
    }
}
.scrollbar-images {
    margin-bottom: 12px;
}
.visible-wrapper {
    img {
        width: calc(230px + (330 - 230) * ((100vw - 320px) / (1920 - 320))) !important;
        height: calc(230px + (330 - 230) * ((100vw - 320px) / (1920 - 320)));
    }
}
.scroll-demo {
    padding: 10px;
    position: relative;
    border: 1px solid $light-color;
    overflow: auto;
    .horz-scroll-content {
      width: 1600px;
      .horizontal-img {
        height: 100%;
        max-width: 240px;
        @media (max-width:576px) {
            max-width: calc(220px + (240 - 220) * ((100vw - 320px) / (576 - 320)));
        }
        img {
            height: 100%;
            object-fit: cover;
        }
    }
    }
    &.scroll-b-none {
        padding: 0;
        border: none;
    }
    .scroll-rtl {
        .list-group-item {
            span {
                [dir="rtl"] & {
                    margin-right: auto;
                }
            }
        }
    }
}
/**=====================
    2.36 Scrollbar CSS Ends
==========================**/
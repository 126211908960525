/**=====================
   3.1 Blog CSS start
==========================**/
.blog-box {
  img {
    border-radius: 0.25rem;
  }
  .top-radius-blog {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  h6 {
    font-weight: 600;
    margin: 15px 0;
  }
  .blog-details {
    padding-right: 20px;
    .blog-social {
      margin-top: 30px;
      li {
        display: inline-block;
        font-size: 12px;
        color: $theme-body-sub-title-color;
        +li {
          padding-left: 20px;
          line-height: 1;
          padding-right: 0;
        }
        i {
          padding-right: 5px;
          font-weight: 300;
        }
        &:first-child {
          border-right: 1px solid gray;
          padding-right: 20px;
        }
      }
      &.blog-sub-content{
        display: block;

        li{
          padding: 0;
          padding-right: 20px;
          border-radius: 0;

          +li{
            padding: 0;
            padding-left: 20px;
          }
        }
      }
    }
  }
  .blog-details-main {
    hr {
      background-color: $gray-60;
    }
    .blog-social {
      margin: 20px 0;
      li {
        display: inline-block;
        font-size: 14px;
        color: $theme-body-sub-title-color;
        padding: 0 24px;
        border-right: 1px dotted;
        @media (max-width: 1676px) {
          padding: 0 10px;
        }
        &:last-child {
          border-right: 0;
        }
        i {
          padding-right: 5px;
        }
      }

      &.feature_contents{
        li{
          &:first-child{
            border-radius: 0;
          }
        }
      }
    }
    .blog-bottom-details {
      margin: 20px;
      font-size: 14px;
      line-height: 1.714;
      font-weight: 600;
    }
  }
  .blog-date {
    color: $theme-body-sub-title-color;
    span {
      font-size: 36px;
      font-weight: 500;
      padding-right: 5px;
      color: $primary-color;
    }
  }
}
.blog-box.blog-shadow {
  &:before {
    box-shadow: inset 0px -200px 100px -13px rgba(0, 0, 0, 0.6);
    transition: all 0.3s ease;
    border-radius: 5px;
    content: "";
    position: absolute;
    height: 100%;
    top: 0;
    width: 100%;
    bottom: 0;
  }
  &:hover {
    &:before {
      box-shadow: inset 0px -200px 100px -13px rgba(0, 0, 0, 0.65);
      transition: all 0.3s ease;
    }
  }
  .blog-details {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 20px;
    h4 {
      color: $white;
      margin-bottom: 0;
    }
    p {
      font-size: 14px;
      color: $white;
      margin-bottom: 10px;
    }
    .blog-social {
      li {
        color: $white;
      }
    }
  }
}
.blog-list {
  .blog-details {
    .blog-social {
      margin-top: 0;
    }
    h6 {
      margin-top: 0;
      color: $black;
      font-weight: 600; 
      font-size: 18px;
    }
    hr {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  img {
    padding: 17px;
  }
}
.blog-grid {
  img {
    width: 100%;
  }
  .blog-details {
    padding: 20px;
    h6 {
      font-size: 14px;
      margin-bottom: 0;
      line-height: 1.8;
    }
  }
}
.blog-box {
  position: relative;
  max-height: 100%;
  align-items: center;
  .blog-details {
    h6 {
      text-overflow: ellipsis;
      overflow: hidden;
      -webkit-line-clamp: 1;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }
    p {
      @media (max-width: 1570px) {
        -webkit-line-clamp: 2;
      }
    }
    h4 {
      font-size: 18px;
      line-height: 1.5;
      margin-bottom: 10px;
      font-weight: 700;
    }
    .single-blog-content-top {
      margin-top: 20px;
    }
    .blog-social,
    .comment-social {
      li {
        font-size: 14px;
      }
    }
  }
}
.comment-box {
  padding-top: 50px;
  padding-bottom: 50px;
  hr {
    margin-top: 20px;
    margin-bottom: 30px;
  }
  li {
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  ul {
    ul {
      margin-left: 135px;
    }
  }
  p {
    font-size: 14px;
    line-height: 2;
    color: $theme-body-sub-title-color;
    text-align: justify;
    margin-bottom: 0;
  }
  h4 {
    font-weight: 600;
  }
  .d-flex {
    img {
      margin-right: 30px;
      border-radius: 10px;
      height: 90px;
      padding: 7px;
      width: 90px;
      border: 1px solid $light-semi-gray;
    }
    h4 {
      font-weight: 600;
      margin-bottom: 5px;
      font-size: 16px;
      span {
        padding-left: 10px;
        font-size: 13px;
        color: $theme-body-sub-title-color;
        font-weight: 400;
      }
    }
  }
  .comment-social {
    li {
      display: inline-block;
      color: $dark-color;
      padding-left: 20px;
      margin-bottom: 0;
      text-transform: uppercase;
      &:first-child {
        border-right: 1px solid $dark-color;
        padding-right: 20px;
        border-radius: 0;
      }
      i {
        padding-right: 10px;
      }
    }
  }
}
/**=====================
   3.1 Blog CSS ends
==========================**/
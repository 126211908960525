/**=====================
	3.30 Megaoption CSS Start
==========================**/
.mega-inline {
	margin-bottom: -30px;
	display: flex;

	.card {
		border: 1px solid #f3f3f3;
		box-shadow: $card-box-shadow;
	}

	.mega-title-badge {
		display: table;
		width: 100%;
		font-size: 14px;
		font-weight: 600;
		text-transform: uppercase;
	}

	.d-flex {
		.radio {
			margin-left: 3px;
		}
	}
}

.mega-vertical {
	display: flex;
	flex-wrap: wrap;

	.card {
		box-shadow: $card-box-shadow;
	}

	.mega-title-badge {
		display: table;
		width: 100%;
		font-size: 14px;
		font-weight: 600;
		text-transform: uppercase;
	}

	.d-flex {
		.radio {
			margin-left: 3px;
		}
	}
}

.mega-title {
	font-weight: 600;
}

.mega-horizontal {
	display: flex;
	flex-wrap: wrap;

	.card {
		border: 1px solid #f3f3f3;
		box-shadow: $card-box-shadow;
	}

	.mega-title-badge {
		display: table;
		width: 100%;
		font-size: 14px;
		font-weight: 600;
		text-transform: uppercase;
	}

	.d-flex {
		.radio {
			margin-left: 3px;
		}
	}
}

.plain-style {
	.card {
		box-shadow: none;
		border: none !important;
	}
}

.border-style {
	.card {
		box-shadow: none;
		border: 1px solid $light-gray !important;
	}
}

.offer-style {
	.card {
		box-shadow: none;
		border: 1px dashed $light-gray !important;
	}
}

.rating-star-wrapper {
	color: #797979;
}
.card-wrapper {
	.payment-wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-bottom: 8px;
		margin-bottom: 16px;
		border-bottom: 1px solid var(--chart-dashed-border);

		&:last-child {
			padding-bottom: 0;
			margin-bottom: 0;
			border-bottom: unset;
		}

		.payment-second {
			img {
				width: 50px;
			}

			.mega-icons {
				width: 20px;
				height: 20px;
			}

		}
	}
}.card-wrapper {
	.payment-wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-bottom: 8px;
		margin-bottom: 16px;
		border-bottom: 1px solid var(--chart-dashed-border);

		&:last-child {
			padding-bottom: 0;
			margin-bottom: 0;
			border-bottom: unset;
		}

		.payment-second {
			img {
				width: 50px;
			}

			.mega-icons {
				width: 20px;
				height: 20px;
			}

		}
	}
}
// Custom checkbox
.main-upgrade {
	margin: 0 auto;
	text-align: center;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	>div {
		&:first-child {
			margin-bottom: 20px;
		}
	}

	i {
		font-size: calc(40px + (50 - 40) * ((100vw - 320px) / (1920 - 320)));
		display: block;
		color: $primary-color;
	}

	p {
		line-height: 1.4;
	}

	.variation-box {
		gap: 20px;
		display: flex;
		justify-content: center;
		align-items: center;

		@media (min-width:768px) and (max-width:1200px) {
			display: grid;
			grid-template-columns: auto auto;
			gap: 18px;
		}

		@media (max-width:700px) {
			display: grid;
			grid-template-columns: auto auto;
			gap: 18px;
		}

		@media (max-width:380px) {
			grid-template-columns: unset;
		}

		.selection-box {
			position: relative;
			height: 100px;
			border: 1px dashed $primary-color;
			min-width: 125px;
			border-radius: 15px;

			@media (min-width:1200px) and (max-width:1920px) {
				min-width: calc(110px + (125 - 110) * ((100vw - 1200px) / (1920 - 1200)));
			}

			.custom--mega-checkbox {
				width: 100%;
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				line-height: 25px;
				transition: .5s ease;
			}

			input {
				position: absolute;
				top: 0;
				left: 0;
				width: 118px;
				height: 96px;
				opacity: 0;
				cursor: pointer;


				[dir="rtl"] & {
					left: unset;
					right: 0;
				}

				&[type=checkbox]:checked~div {
					background-color: $light-primary;
					border-radius: 15px;
				}
			}
		}
	}

}
/**=====================
    3.30 Megaoption CSS Ends
==========================**/